<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span class="label">订单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.order_number"></i-input>
        </FormItem>
        <FormItem>
          <span class="label">下单时间：</span>
          <DatePicker class="iviewIptWidth250" format="yyyy-MM-dd" placeholder="请选择" :editable="false" type="daterange" placement="bottom-end" @on-change="changeTime($event)"></DatePicker>
        </FormItem>
        <FormItem>
          <span class="label">订单类型：</span>
          <Select class="iviewIptWidth250" v-model="queryFrom.purchase_order_type" clearable>
            <Option v-for="(item, index) in orderTypeList" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">订单状态：</span>
          <Select class="iviewIptWidth250" v-model="queryFrom.purchase_order_state" clearable @on-change="stateChange">
            <Option v-for="(item, index) in orderStateList" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem :label-width="0">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          <div>
            <span style="margin-right:20px"
              >总数量：<span class="color389">{{ totalNum }}</span></span
            >
            <span>合计金额：</span>
            <span class="color389">¥{{ totalMoney }}</span>
          </div>
        </div>
      </Table>
    </div>

    <!--    &lt;!&ndash; 确认弹窗 &ndash;&gt;-->
    <!--    <Modal v-model="affirm" footer-hide width="360" class="affirm">-->
    <!--      <p>是否确认？</p>-->
    <!--      <span class="pageBtn finger btnSure" @click="notarize">确认</span>-->
    <!--      <span class="pageBtn finger cancel" @click="affirm = false">取消</span>-->
    <!--    </Modal>-->
    <!--    审核-->
    <Modal v-model="affirm" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="notarize">确认</span>
          <span class="pageBtn finger btnCancle" @click="affirm = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'purchaseOrder',
  components: {
    Table
  },
  data() {
    return {
      key: null,
      order: null,
      order_number: '',
      affirm: false,
      isLoad: false,
      totalNum: 0,
      totalMoney: 0,
      cityList: [],
      navFrist: [
        {
          name: '全部',
          num: 140,
          type: '-2'
        },
        {
          name: '待确认',
          num: 40,
          type: '1'
        },
        {
          name: '待发货',
          num: 40,
          type: '8'
        },
        {
          name: '待开票',
          num: 40,
          type: '4'
        }
      ],
      orderTypeList: [
        {
          name: '普通采购',
          value: 1
        },
        {
          name: '委托代销',
          value: 2
        },
        {
          name: '自寄售',
          value: 3
        },
        {
          name: '上游寄售',
          value: 4
        }
      ],
      orderStateList: [
        {
          name: '待确认',
          value: '1'
        },
        {
          name: '待发货',
          value: '8'
        },
        {
          name: '部分发货',
          value: '9'
        },
        {
          name: '待收货',
          value: '2'
        },
        {
          name: '待开票',
          value: '4'
        },
        {
          name: '已完成',
          value: '5'
        }
      ],
      clickIndex: -2,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65
        },
        {
          title: '订单号',
          align: 'center',
          width: 150,
          key: 'order_number',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    }
                  }
                },
                param.row.order_number
              )
            ])
          }
        },
        {
          title: '产品',
          key: 'product_list',
          minWidth: 250,
          align: 'center'
        },
        {
          title: '数量',
          key: 'total_number',
          width: 80,
          align: 'center'
        },
        {
          title: '订单金额',
          align: 'center',
          width: 130,
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.total_amount)])
          }
        },
        {
          title: '下单时间',
          sortable: 'custom',
          key: 'insert_time',
          width: 160,
          align: 'center'
        },
        {
          title: '备注',
          key: 'note',
          minWidth: 160,
          align: 'center'
        },
        {
          title: '订单类型',
          key: 'purchase_order_type_str',
          width: 150,
          align: 'center'
        },
        {
          title: '订单状态',
          key: 'purchase_order_state_str',
          width: 150,
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            let status = param.row.purchase_order_state
            if (status == '1') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    },
                    class: { disableUse: this.modifyAble },
                    on: {
                      click: () => {
                        this.order_number = param.row.order_number
                        this.affirm = true
                      }
                    }
                  },
                  '确认'
                )
              ])
            } else if (status == '8' || status == '9') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    },
                    class: { disableUse: this.modifyAble },
                    on: {
                      click: () => {
                        this.ship(param.row)
                      }
                    }
                  },
                  '发货'
                )
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer'
                    },
                    on: {
                      click: () => {
                        this.upload(param.row)
                      }
                    }
                  },
                  '导出采购单'
                )
              ])
            }
          }
        }
      ],
      listData: [],
      total: 1,
      queryFrom: {
        page: 1,
        rows: 10,
        is_supplier: '1',
        order_number: '',
        supplier_id: '',
        start_insert_time: '',
        end_insert_time: '',
        purchase_order_type: '',
        purchase_order_state: '',
        dimension: ''
      },
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false // 查找权限
    }
  },
  activated () {
    if(sessionStorage.getItem('updateList') == 0) {
      this.query()
      sessionStorage.setItem('updateList','1')
    }
    
  },
  mounted() {
    if (this.$route.query.show) {
      this.queryFrom.purchase_order_state = '1'
      this.clickIndex = '1'
    }
    this.query()
    this.getRightVisibal()
  },
  methods: {
    sortChange(data) {
      console.log(data)
      this.queryFrom.sort_str = data.key
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    // 去发货
    ship(row) {
      this.$router.push({
        path: '/orderShip',
        query: {
          id: row.id
        }
      })
    },
    // 弹窗-确认
    notarize() {
      this.isLoad = true
      this.$http.put(this.$api.pruchaseOrderConfirm, { order_number: this.order_number }, true).then(res => {
        this.isLoad = false
        this.affirm = false
        this.query()
      })
    },
    // 订单详情
    goDetails(row) {
      this.$router.push({
        path: '/purchaseOrderDetails',
        query: {
          id: row.id
        }
      })
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.start_insert_time = null
        this.queryFrom.end_insert_time = null
      } else {
        this.queryFrom.start_insert_time = e[0]
        this.queryFrom.end_insert_time = e[1]
      }
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 切换Tab页
    clickNav(type) {
      this.clickIndex = type
      if (type != '-2') {
        this.queryFrom.purchase_order_state = type
      } else {
        this.queryFrom.purchase_order_state = ''
      }
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      this.$http.get(this.$api.pruchaseOrderManage, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_amount
        this.totalNum = res.total_number
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].insert_time = res.data[i].insert_time ? this.$moment(res.data[i].insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        for (let i = 0; i < res.supplier_tab_count.length; i++) {
          this.navFrist[i].num = res.supplier_tab_count[i]
        }
      })
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 状态改变
    stateChange(e) {
      console.log(e)
      if (e == '1' || e == '8' || e == '4') {
        this.clickIndex = e
      } else {
        this.clickIndex = '-2'
      }
      this.query()
    },
    upload(row) {
      this.$http.downFile(this.$api.pruchaseOrderExport, { id: row.id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '采购订单' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    }
  }
}
</script>

<style scoped lang="less">
.affirm {
  text-align: center;
  p {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .cancel {
    margin-left: 40px;
    background-color: #f5f5f5;
    color: black;
    border: 1px solid #f5f5f5;
  }
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;

  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }

  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .topNavDiv {
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }

    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
