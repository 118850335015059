<style lang="less">
/*@import '../../styles/common.less';*/
/*@import './general-assembly.less';*/

.pages {
  margin-top: 10px;
  overflow: hidden;
  line-height: 32px;
}
.ivu-btn {
}
.ivu-btn-text {
  color: rgb(4, 95, 241) !important;
}
.ivu-btn-text:hover {
  color: #529dee !important;
}
.ivu-btn-small {
  color: rgb(4, 99, 250) !important;
}
.ivu-btn-small:hover {
  color: #5ea7f5 !important;
}
.tabDiv {
  position: relative;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.totalFont {
  font-size: 14px;
}
</style>
<template>
  <!-- 通用列表 -->
  <div class="clearfix tabDiv">
    <!-- <div v-if="isLoad" style="height: 100%; width: 100%; position: absolute">
      <Spin fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
    </div> -->
    <Table :height="height" :columns="productList" :data="productData" @on-sort-change="sortChange" @on-selection-change="getSelection" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectionAllCancel" highlight-row border>
      <template v-for="column in productList" :slot="column.slot ? column.slot : ''" slot-scope="params">
        <slot :name="column.slot ? column.slot : ''" v-bind="params"></slot>
      </template>
    </Table>
    <slot name="summary"></slot>
    <div class="pages" v-if="productData !== null && productData.length > 0 && total">
      <Page v-if="pages && total <= 60" :show-sizer="showSizer" @on-page-size-change="onPageSizeChange" :show-elevator="showElevator" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
      <Page v-if="pages && total > 60" :show-sizer="showSizer" @on-page-size-change="onPageSizeChange" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
      <div class="fr totalFont" v-if="total && totalText">共计{{ total }}{{ totalText }}</div>
    </div>
    <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important" class="clearfix noData" v-if="productData !== null && productData.length == 0 && (imgType == 1 || !imgType) && isLoad == false">
      <img style="margin-top: 100px" src="../assets/images/tableNoData.png" />
    </div>
    <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important" class="clearfix noData" v-if="productData !== null && productData.length == 0 && imgType == 2 && isLoad == false">
      <img style="margin-top: 100px" src="../assets/images/tableNoData.png" />
    </div>
  </div>
  <!-- 通用列表 -->
</template>
<script>
export default {
  name: 'tableList',
  components: {},
  props: {
    total: Number,
    productList: Array,
    productData: {
      type: Array,
      default: function() {
        return []
      }
    },
    pages: Object,
    height: String,
    imgType: Number || 1,
    isLoad: Boolean,
    totalText: String,
    showSummary: Boolean,
    showSizer: Boolean,
    showElevator: Boolean
  },
  data() {
    return {}
  },
  mounted() {
    // this.ruleValidate=this.$parent.yzMessage
  },
  methods: {
    changePage(e) {
      this.$emit('change-page', e)
    },
    // column：当前列数据  key：排序依据的指标  order：排序的顺序，值为 asc 或 desc
    sortChange(data) {
      this.$emit('on-sort-change', data)
    },
    // 在多选模式下有效，只要选中项发生变化时就会触发
    getSelection(data) {
      this.$emit('on-selected', data)
    },
    // 在多选模式下有效，选中某一项时触发
    onSelect(data, row) {
      this.$emit('on-select', data, row)
    },
    // 在多选模式下有效，取消选中某一项时触发
    onSelectionCancel(data, row) {
      this.$emit('on-select-cancel', data, row)
    },
    // 在多选模式下有效，点击全选时触发
    onSelectAll(data, row) {
      this.$emit('on-select-all', data)
    },
    // 在多选模式下有效，点击取消全选时触发
    onSelectionAllCancel(data, row) {
      this.$emit('on-select-all-cancel', data)
    },
    onPageSizeChange(e) {
      this.$emit('on-page-size-change', e)
    }
  }
}
</script>
